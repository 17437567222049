import React, { useState, useEffect } from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from '../components/header'
import { default as Footer } from '../components/footer'
import ModelGrid from '../components/ModelGrid'

import ApiService from '../data/services/ApiService'
import queryString from 'query-string'
// import * as Remarkable from 'remarkable'
const { Remarkable } = require('remarkable')
const md = new Remarkable()

interface Props {
    location: {
        search?: any
    }
}

interface Model {
    id?: string
    avatar?: string
    name?: string
    description?: string
    slug?: string
    createdAt?: string
    updatedAt?: string
}

export interface ParsedQuery<T = string> {
    [key: string]: T | T[] | null | undefined
}

const Model = (props: Props) => {
    const [model, setModel]: any = useState({
        // id: '',
        // name: '',
        // avatar: '',
        // description: '',
        // slug: ''
    })

    useEffect(() => {
        (async () => {
            const values: ParsedQuery<string> = queryString.parse(props.location.search)
            const m = values.model || ''
            const response = await ApiService.getModelBySlug(m.toString())

            setModel(response.data.model)
        })()
    }, [])

    return(
        <section className={styles.Container}>
            <Header />
            <div className={styles.ModelPage}>
                <section className={styles.Centered}>
                    <div className={styles.Avatar}>
                        <img src={`${ApiService.getBaseURL()}/uploads/models/${model.avatar}`} />
                    </div>
                    <div className={styles.Details}>
                        <h1>{model.name}</h1>

                        <div dangerouslySetInnerHTML={{ __html: md.render(model.description) }} />
                    </div>

                    <div className={styles.Actions}>
                        <a href={`/book`} className={styles.ButtonPrimary}>Call Me</a>
                        <a href={``} className={styles.Button}>Learn More</a>
                    </div>

                    <ModelGrid />
                </section>
            </div>
            <Footer />
        </section>
    )
}

export default Model